import React, { FC, useRef, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Card, Collapse, Divider, Skeleton, Typography } from 'antd';
import { RefSelectProps } from 'antd/lib/select';

import { SelectItem } from 'types/global';
import { selectedClientState } from 'recoil/atoms';
import { SfAccountsSearch } from 'components/salesforce/SfAccountsSearch';
import './AccountAdmin.scss';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { getSalesforceAccount } from '../../api/salesforce';
import { getSelectedClientUsers, selectClientEmailDomains } from '../../api/admin';
import { AccountInfoDetails } from '../account-settings/AccountInfoDetails';

const { Panel } = Collapse;

const AccountAdmin: FC = () => {
  const searchRef = useRef<RefSelectProps>(null);
  const setSelectedClient = useSetRecoilState(selectedClientState);
  const selectedClient = useRecoilValue(selectedClientState);
  const [accountInfo, setAccountInfo] = useState<any>();
  const [emailDomains, setEmailDomains] = useState<any[]>([]);
  const [activeUsers, setActiveUsers] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (selectedClient && selectedClient?.key) {
        setLoading(true);
        const sfAccountInfo = await getSalesforceAccount(selectedClient?.key?.toString());
        setAccountInfo(sfAccountInfo);
        const emailDomains = await selectClientEmailDomains(selectedClient?.key?.toString());
        setEmailDomains(emailDomains);
        const res = await getSelectedClientUsers();
        if (Array.isArray(res?.data)) setActiveUsers(res?.data?.filter((user: any) => !user.blocked).length);
        setLoading(false);
      }
    })();
  }, [selectedClient]);

  const handleSfAccountSearchChange = async (val: SelectItem | undefined) => {
    if (val) {
      setSelectedClient(val);
    } else {
      setSelectedClient(undefined);
    }
  };

  useEffect(() => {
    // Set focus in SfAccountsSearch component
    searchRef.current?.focus();
  }, []);

  return (
    <div className="account-admin ml-4">
      <Typography.Title level={4}>Account Management</Typography.Title>
      <Divider />
      <Card>
        <Typography.Title level={4}>Search Account</Typography.Title>
        <SfAccountsSearch ref={searchRef} className="w-[380px]" onChange={handleSfAccountSearchChange} />
        {selectedClient && (
          <>
            <Divider />
            <Collapse
              bordered={false}
              defaultActiveKey={['1', '2']}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusSquareOutlined className="text-blue-500" />
                ) : (
                  <PlusSquareOutlined className="text-blue-500" />
                )
              }
            >
              <Panel header={selectedClient?.label} key="1">
                <Skeleton loading={loading}>
                  <AccountInfoDetails accountInfo={accountInfo} emailDomains={emailDomains} activeUsers={activeUsers} />
                </Skeleton>
              </Panel>
              {/*<Panel header="Associated Users" key="2">*/}
              {/*  <UserManagement />*/}
              {/*</Panel>*/}
            </Collapse>
          </>
        )}
      </Card>
    </div>
  );
};

export default AccountAdmin;
