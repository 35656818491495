import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';

const SiteAdminCards: React.FC = () => {
  return (
    <div>
      <h1>Site Administration </h1>
      <Card title="Supplier/Reseller Admin" bordered={false} className="bg-gray-200 mb-4 rounded-xl">
        <Row gutter={[24, 16]}>
          <Col span={6}>
            <Link to="suppliers">
              <Card title="Suppliers">{'Manage Supplier Metadata'}</Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to="products">
              <Card title="Products">{'Manage Supplier Products'}</Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to={'price_lists'}>
              <Card title="Price Lists">{'Manage Supplier Price Lists'}</Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to={'product_accuracy'}>
              <Card title="BOM Accuracy">{'Correct Suspected BOM Inaccuracies'}</Card>
            </Link>
          </Col>

          <Col span={6}>
            <Link to={'supplier_dynamics'}>
              <Card title="Supplier Dynamics">{'Manage Supplier Dynamics Scores'}</Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to={'resellers'}>
              <Card title="Resellers">{'Manager Reseller Metadata'}</Card>
            </Link>
          </Col>
        </Row>
      </Card>

      <Card title="Client Admin" bordered={false} className="bg-gray-200 mb-4 rounded-xl">
        <Row gutter={24}>
          <Col span={6}>
            <Link to={'clients'}>
              <Card title="Clients">{'Manage Clients and Client Metadata'}</Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to={'account_admin'}>
              <Card title="Account Admin">{'Lorem ipsum dolor sit amet, consr adipiscing elit.'}</Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to={'users'}>
              <Card title="Users">{'View Vanguard Users'}</Card>
            </Link>
          </Col>
        </Row>
      </Card>

      <Row gutter={[24, 16]}>
        <Col span={6}>
          <Card title={'Inquiry Admin'} bordered={false} className="bg-gray-200 mb-4 rounded-xl">
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Link to={'triage'}>
                  <Card title={'Triage'}>{'Manage and Assign Client Inquiries'}</Card>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={'workbench'}>
                  <Card title={'Work Bench'}>{'Analylst Workbench'}</Card>
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={6}>
          <Card title={'Access Control'} bordered={false} className="bg-gray-200 mb-4 rounded-xl">
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Link to={'permissions'}>
                  <Card title={'Site Permissions'}>{'Manage Site Permissions'}</Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link to={'roles'}>
                  <Card title={'User Roles'}>{'Manage User Roles and Permissions'}</Card>
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={6}>
          <Card title={'Content Moderation'} bordered={false} className="bg-gray-200 mb-4 rounded-xl">
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Link to={'community_moderation'}>
                  <Card title={'Community Moderation'}>{'Moderate Community Posts'}</Card>
                </Link>
              </Col>

              <Col span={24}>
                <Link to={'review_moderation'}>
                  <Card title={'Moderate Supplier Reviews'}>{'Moderate Supplier Reviews'}</Card>
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={6}>
          <Card title={'Marketing Administration'} bordered={false} className="bg-gray-200 mb-4 rounded-xl">
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Link to={'banner_administration'}>
                  <Card title={'Banner Administration'}>{'Manage Site Banners'}</Card>
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SiteAdminCards;
