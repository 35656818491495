import React, { FC } from 'react';
import { Descriptions, Tag } from 'antd';
import { useRecoilState } from 'recoil';
import { accountSettingsMenuKeyState } from 'recoil/atoms';
import moment from 'moment';
import { FormatCurrency } from 'shared/Helpers';
import './AccountInfo.scss';
import { AccountData } from '../../models/AccountData';

interface AccountInfoDetailsProps {
  accountInfo?: AccountData;
  emailDomains?: string[];
  activeUsers: number;
}

export const AccountInfoDetails: FC<AccountInfoDetailsProps> = ({
  accountInfo,
  emailDomains,
  activeUsers
}: AccountInfoDetailsProps) => {
  const [_accountSettingsMenuKey, setAccountSettingsMenuState] = useRecoilState(accountSettingsMenuKeyState);

  const renderMsg = () => {
    return (
      <p>
        To request a change to the domains your team can use to authenticate, please contact your NPI customer service
        director.
      </p>
    );
  };
  return (
    <Descriptions column={1} colon={true} labelStyle={{ width: 180 }} bordered>
      <Descriptions.Item label="Phone">{accountInfo?.Phone}</Descriptions.Item>
      <Descriptions.Item label="Annual Revenue">{FormatCurrency(accountInfo?.AnnualRevenue)}</Descriptions.Item>
      <Descriptions.Item label="Status">{accountInfo?.Status__c}</Descriptions.Item>
      <Descriptions.Item label="Industry">{accountInfo?.Industry}</Descriptions.Item>
      <Descriptions.Item label="Billing Address">
        <div>{accountInfo?.BillingAddress?.street}</div>
        <div>
          {`${accountInfo?.BillingAddress?.city}, ${accountInfo?.BillingAddress?.state} ${accountInfo?.BillingAddress?.postalCode}`}
        </div>
      </Descriptions.Item>

      <Descriptions.Item label="Current Contract">{accountInfo?.Current_Contract__r?.Name}</Descriptions.Item>
      <Descriptions.Item label="Dates">
        {moment(accountInfo?.Current_Contract__r?.StartDate).format('MMM DD, YYYY') +
          ' - ' +
          moment(accountInfo?.Current_Contract__r?.EndDate).format('MMM DD, YYYY')}
      </Descriptions.Item>
      <Descriptions.Item label="Subscription Fee">
        {FormatCurrency(accountInfo?.Current_Contract__r?.Total_Subscription_Fee__c)}
      </Descriptions.Item>
      <Descriptions.Item label="Active Users">
        {
          <span className="text-blue-500 cursor-pointer" onClick={() => setAccountSettingsMenuState('userManagement')}>
            {activeUsers} Active Users
          </span>
        }
      </Descriptions.Item>
      <Descriptions.Item label="Enabled Domains">
        {emailDomains?.map((domain, idx) => (
          <Tag key={idx + domain} color="#d9d9d9" className="text-base px-2 py-1 rounded-lg ml-2 text-black">
            {domain}
          </Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label={undefined}>{renderMsg()}</Descriptions.Item>
    </Descriptions>
  );
};
